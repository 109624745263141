import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import './app.scss';
import Welcome from '../common/welcome/welcome';
import {
    Alignment,
    Button,
    Classes,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Navbar,
    NavbarGroup,
    Popover,
    Position,
    Tag
} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import {LoginComponent} from '../common/login/login';
import {observer} from 'mobx-react';
import {UserModel} from '../../models/user-state';
import {MenuItemLink} from '../common/links/menuitem-link';
import {AppNavigator} from '../../models/navigation';
import {ConfigService} from '../../service/config';
import {IntlProvider} from 'react-intl';
import {OfficeComponent} from '../office/office';
import {storage} from '../../service/storage';
import { OfficeCyclingComponent } from '../office/cycling/cycling';

@observer
class App extends Component {
    constructor(props: any) {
        super(props);
        this.viewUserPage = this.viewUserPage.bind(this);
        this.logOut = this.logOut.bind(this);
        AppNavigator.clear();
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidMount(): void {
        ConfigService.init().then(() => {
            const redirect = storage.get('redirectAfterLogin');
            if (redirect && UserModel.isUserAuthenticated) {
                storage.unset('redirectAfterLogin');
                AppNavigator.go(redirect.pathname);
            }
        }).catch();
    }

    logOut() {
        UserModel.signOut().finally(() => this.forceUpdate());
    }

    viewUserPage() {
        if (UserModel.isUserAuthenticated) {
            return (
                <Redirect
                    to={{ pathname: '/users/' + UserModel.userId + '' }}
                />
            );
        }
    }

    render() {
        document.title = AppNavigator.title;

        return (
            <IntlProvider locale={'en'} >
                <div className="appComponent">
                    <div className={'main'}>

                        <Switch>
                            <Route path="/" exact component={Welcome}/>

                            <Route path="/users/auth/sign-in" component={LoginComponent}/>
                            <Route path="/:companyId/:officeId/cycling/" component={OfficeCyclingComponent}/>
                            <Route path="/:companyId/:officeId/" component={OfficeComponent}/>

                            <Route component={Welcome}/>
                        </Switch>
                    </div>
                </div>
            </IntlProvider>
        );
    }

    AuthenticatedMenu() {
        if (!AppNavigator.online) {
            return <NavbarGroup align={Alignment.RIGHT}>
                <Tag intent={Intent.WARNING}>Offline</Tag>
            </NavbarGroup>;
        }

        if (!UserModel.isUserAuthenticated) {
            return;
        }

        return (
            <NavbarGroup align={Alignment.RIGHT}>

                <Navbar.Divider/>
                <Popover
                    content={
                        <Menu>
                            <MenuItemLink
                                className={Classes.MENU_ITEM}
                                icon={IconNames.LIST_DETAIL_VIEW}
                                text="View"
                                to={'/users/' + UserModel.userId + '/'}
                            />
                            <MenuItemLink
                                className={Classes.MENU_ITEM}
                                icon={IconNames.SETTINGS}
                                text="Settings"
                                to={
                                    '/settings/'
                                }
                            />
                            <MenuDivider/>
                            <MenuItem
                                text="Sign Out"
                                icon={IconNames.LOG_OUT}
                                onClick={() => this.logOut()}
                            />
                        </Menu>
                    }
                    position={Position.BOTTOM_RIGHT}
                >
                    <Button
                        icon={IconNames.USER}
                        rightIcon={IconNames.CARET_DOWN}
                        minimal={true}
                    >
                        <span className="hidden-xs">
                            {UserModel.userName}
                        </span>
                    </Button>
                </Popover>
            </NavbarGroup>
        );
    }
}

/* @ts-ignore
function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}
*/
export default App;
