import {action, computed, observable} from 'mobx';
import { HttpApi } from '../service/http-api';
import { IActivity, IOffice, officeFake } from '../types/office';
import {fakeLeader, ILeader} from '../types/leader';
import { MeasurementSystem } from '../types/measurement-system';
import {OfficeService} from '../service/office';
import { dedupe } from '../utils/dedupe';
import { getCurrentChallengeMonth } from '../utils/formatters';
import { rankByKey } from '../utils/ranker';

const ORGANIZERS = [
    'e03faf73-c4da-499d-a596-e9d79f396514'
];

export class OfficesModel {
    @observable
    filterText  = '';

    @observable
    offices: IOffice[] = [];

    @observable
    isOfficeListLoading = true;

    @observable
    isActiveOfficeLoading = false;

    @observable
    isActivitiesListLoading = false;


    @observable
    isLeaderListLoading = true;

    @observable
    leaders: ILeader[] = [
        fakeLeader,
        fakeLeader,
        fakeLeader,
    ];

    @observable
    activeOffice: IOffice | undefined = undefined;

    @observable
    activities: IActivity[] = [];


    @observable
    activeStravaProfile: ILeader | undefined = undefined;

    @observable
    units: MeasurementSystem = MeasurementSystem.SI;

    @observable
    activeChallengeMonth = getCurrentChallengeMonth();

    @observable
    challengeMonthList: string[] = [ getCurrentChallengeMonth() ];

    @action
    async load() {
        this.isOfficeListLoading = true;
        this.offices = [
            officeFake
        ];
        const officeList = await OfficeService.listOffices('');
        this.offices = officeList.sort((a: IOffice, b: IOffice) => {
            if (a.rank === b.rank) {
                return a.name.localeCompare(b.name);
            } else if (a.rank > b.rank) {
                return -1;
            } else {
                return 1;
            }
        });
        this.isOfficeListLoading = false;
    }

    @computed
    get filteredOffices() {
        const filterText = this.filterText.toLowerCase();
        const isMatching = (a: string) => {
            return a.toLowerCase().includes(filterText);
        };
        return this.offices
            .filter(o => o.active)
            .filter((o) => {
                return isMatching(o.name) ||
                    isMatching(o.address.city) ||
                    isMatching(o.address.street) ||
                    isMatching(o.address.state) ||
                    isMatching(o.address.country) ||
                    isMatching(o.address.zip);
            })
            .slice(0, 5);
    }

    @action
    async loadOffice(officeId: string) {
        this.isActiveOfficeLoading = true;
        this.activeOffice = await OfficeService.getOffice(officeId);
        this.isActiveOfficeLoading = false;
    }

    @computed
    get activeOfficeBackgroundUrl() {
        if (!this.activeOffice || !this.activeOffice.backgroundImage) {
            return '/images/us/boston/garage-garage.jpeg';
        }

        return this.activeOffice?.backgroundImage;
    }

    @action
    async loadOfficeLeaders(officeId: string) {
        this.isLeaderListLoading = true;
        const leaders = await OfficeService.getLeaders(officeId);

        const cms = [ getCurrentChallengeMonth() ];

        leaders.map((l) => (cms.push(l.challengeMonth)));
        // console.log('leaders', leaders, cms);

        this.challengeMonthList = dedupe(cms).sort().reverse();

        this.leaders = leaders;

        this.isLeaderListLoading = false;
    }


    @action
    async loadOfficeActivities(officeId: string) {
        this.isActivitiesListLoading = true;
        const activities = await OfficeService.getActivities(officeId);
        const cms = [ getCurrentChallengeMonth() ];

        // activities.map((l) => (cms.push(l.challengeMonth)));
        // console.log('leaders', leaders, cms);

        this.challengeMonthList = dedupe(cms).sort().reverse();
/*
*/
        this.activities = activities;

        this.isActivitiesListLoading = false;
    }

    @computed
    get officeLeaders() {
        let p = 1;
        return this.leaders
            .filter(o => o.challengeMonth === this.activeChallengeMonth)
            .map(l => {
                // because local totalDistance in meters
                // we assume that 10000km per month is crazy and whoever made it and biked to work too deserves the perk
                const rankingWeight = (ORGANIZERS.includes(l.userId) ? 0 : (10**10)) + l.points * (10**8) + l.totalDistance;
                return {...l, rankingWeight};
            })
            .sort((a: ILeader, b: ILeader) => {
                return rankByKey(a, b, 'rankingWeight');
            })
            .map(l => {
                // console.log('officeLeaders', l);
                if (ORGANIZERS.includes(l.userId)) {
                    l.rankingPosition = 0;
                } else {
                    l.rankingPosition = p++;
                }
                return l;
            });
    }

    convertDistance(distance: number): string {
        switch (this.units) {
            case MeasurementSystem.Customary:
                return (distance * 0.000621371).toFixed(0);
            case MeasurementSystem.SI:
                return (distance * 0.001).toFixed(0);
        }
    }

    @action
    async loadStravaProfile() {
        try {
            const { profile } = await HttpApi.get('strava/profile');
            if (!profile) {
                this.activeStravaProfile = undefined;
                return;
            }
            this.activeStravaProfile = profile;
        } catch (e) {
            this.activeStravaProfile = undefined;
        }
    }
}

export const om = new OfficesModel();
