import React, { Component } from 'react';
import { Button, ButtonGroup, Callout, H1, H2, Intent } from '@blueprintjs/core';
import './cycling.scss';
import { IconNames } from '@blueprintjs/icons';
import { AppNavigator } from '../../../models/navigation';
import {RouteComponentProps, withRouter} from 'react-router';
import {om} from '../../../models/offices';
import {observer} from 'mobx-react';
import { Classes } from '@blueprintjs/core/';
import { UserModel } from '../../../models/user-state';
import LoginComponent from '../../common/login/login';
import { HttpApi } from '../../../service/http-api';
import { storage } from '../../../service/storage';
import { Link } from 'react-router-dom';
import {ILeader} from '../../../types/leader';
import ReactMarkdown from 'react-markdown';

interface IOfficeProps extends RouteComponentProps<any> {
    companyId: string;
    officeId: string;
}

@observer
export class Cycling extends Component<IOfficeProps> {
    componentDidMount(): void {
        AppNavigator.clear();
        AppNavigator.add('/', 'Bike to Work');
        const { officeId } = this.props.match.params;
        if (officeId) {
            om.loadOffice(officeId).then(() => {
                if (om.activeOffice) {
                    AppNavigator.add(`/${om.activeOffice.companyId}/${om.activeOffice.officeId}/`,
                        om.activeOffice.name);
                }
            });
            om.loadOfficeLeaders(officeId).then();
            om.loadStravaProfile().then();
        }
    }

    public renderStravaConnectBanner() {
        return <Callout title={'Connect your Strava profile to start tracking your efforts'}
                        intent={Intent.NONE} icon={IconNames.CYCLE} >
            <p>First matched activity will place you on the leaderboard.</p>
            {this.renderStravaConnectButton('Connect Strava')}
        </Callout>;
    }
    public renderStravaConnectButton(text: string) {
        return (<Button onClick={() => {
                const userId = storage.get('submitterId');
                const {officeId, companyId } = this.props.match.params;

                HttpApi.get(`strava/join?userId=${userId}&officeId=${officeId}&companyId=${companyId}`)
                    .then((data) => {
                        AppNavigator.go(data.url, true);
                    });
            }} intent={Intent.PRIMARY}>{text}</Button>
        );
    }

    public renderStravaConnection() {
        if (!om.activeStravaProfile) {
            return this.renderStravaConnectBanner();
        }

        return <Callout intent={Intent.SUCCESS} title={'Strava Connected'}>
            <div className={'stravaProfile'}>
                <div className='picture'><img
                    src={om.activeStravaProfile.picture} width={50} height={50}
                    alt={om.activeStravaProfile.firstName}/></div>
                <div className='fio'>
                    <a href={'https://www.strava.com/athletes/'+ om.activeStravaProfile.stravaUsername}
                       rel="noopener noreferrer"
                       target={'_blank'}>
                        {om.activeStravaProfile.firstName} {om.activeStravaProfile.lastName}
                    </a>
                </div>
            </div>
            <div className='reconnect'>
                {this.renderStravaConnectButton('Reconnect Strava')}
            </div>
        </Callout>;
    }

    public renderStatus() {
        if (UserModel.isUserAuthenticated) {
            return this.renderStravaConnection();
        } else {
            return <Callout title={'Join the Challenge'} intent={Intent.NONE} icon={IconNames.CYCLE}>
                <p>Sign in using single-sign-on flow. Then you will be prompted to connect your Strava account.</p>
                <LoginComponent redirectToReferrer={true} />
            </Callout>;
        }
    }

    public makeStravaProfileUrl(leader: ILeader) {
        const { stravaUsername } = leader;

        if (!stravaUsername) {
            return '#athlete-has-no-strava-username';
        }

        return 'https://www.strava.com/athletes/'+ stravaUsername;
    }

    public renderLeader(leader: ILeader, place: number) {
        const leadersLoadingClass = om.isLeaderListLoading ?  Classes.SKELETON : '';

        return (
            <div className={'leader'} key={place}>
                <div className='place'>{
                    leader.rankingPosition ? (<span>{leader.rankingPosition}.</span>) : (<span title={'Organizers can\'t participate in the challenge'}>-</span>)
                }</div>
                <div className='picture'><img src={leader.picture}
                                              width={50}
                                              height={50}
                                              alt={leader.userId}
                                              className={leadersLoadingClass}
                /></div>
                <div className='info'>
                    <div className='fio'>
                        <a href={this.makeStravaProfileUrl(leader)}
                           target={'_blank'} rel="noopener noreferrer"
                           className={leadersLoadingClass}>
                            {leader.firstName} {leader.lastName}
                        </a>
                    </div>
                    <div className='points'>
                        Gained points: <b className={leadersLoadingClass}>{leader.points}</b>.
                        Last 4 weeks distance:
                        <span className={leadersLoadingClass}>{om.convertDistance(leader.totalDistance)}</span> km.
                    </div>
                </div>
            </div>
        );
    }

    public renderChallengeMonthSelector() {
        return <ButtonGroup minimal={true} className={'challenge-month-selector'}>
                {om.challengeMonthList.map((m) => (
                    <Button key={m}
                            small={true}
                            active={om.activeChallengeMonth === m}
                            onClick={() => {
                                om.activeChallengeMonth = m;
                            }}
                    >{m}</Button>
                ))}
            </ButtonGroup>;
    }

    public renderLeaderboard() {
        return <div className={'leaderboard'}>
            <H1>Cycling Leaders</H1>
            <div>
                <div>Challenge Month: {this.renderChallengeMonthSelector()}</div>
                <div className='leader-header'>
                    <div>Place</div>
                    <div>Photo</div>
                    <div>Information</div>
                </div>
                {om.officeLeaders.length === 0 ? (<div className={'leader-empty'}>
                        <p><b>Leaderboard is empty.</b></p>
                        <p>You have the chance to become the leader!</p>
                        <p>Sign & connect your Strava account.</p>
                </div>
                ) : (
                om.officeLeaders.map(this.renderLeader.bind(this))
                )}
                <p className={Classes.TEXT_MUTED}>
                    Leaderboard gets updated everytime there is a new ride notification from Strava.
                </p>
            </div>
        </div>;
    }

    public renderInstructions() {
        if (!om.activeOffice) {
            return;
        }

        if (!om.activeOffice.instructions) {
            return;
        }

        return (
            <ReactMarkdown children={om.activeOffice?.instructions} />
        );
    }

    public render() {
        const extraClass = om.activeOffice ? '' : Classes.SKELETON ;
        return (
            <div className={'cyclingComponent'} style={{backgroundImage: `url(${om.activeOfficeBackgroundUrl})`}}>
                <div className={'overlay'}>
                    <div className={'container'}>
                        <div className='officeHeader'>
                            <H1 className={extraClass}>{om.activeOffice?.name || '...'}</H1>
                        </div>
                        <div className='officeIntro'>
                            <div className='left'>
                                <div className='address'>
                                    <p className={extraClass}>{om.activeOffice?.address.street|| '.'}</p>
                                    <p className={extraClass}>{om.activeOffice?.address.city|| '.'}, {om.activeOffice?.address.state || '.'} {om.activeOffice?.address.zip || '.'}</p>
                                    <p className={extraClass}>{om.activeOffice?.address.country|| '.'}</p>
                                    <p className={extraClass}>Latitude: {om.activeOffice?.lat|| '.'}</p>
                                    <p className={extraClass}>Longitude: {om.activeOffice?.lon|| '.'}</p>
                                    <p className={extraClass} title={'We detect starts and stops using this radius'}>Trigger radius: {om.activeOffice?.radius|| '.'} m.</p>
                                </div>
                                {this.renderLeaderboard()}
                            </div>
                            <div className='right'>
                                <img src={process.env.PUBLIC_URL + om.activeOffice?.picture} height={250} width={250} alt='Office' className={extraClass} />
                                <div className={'signInOrConnect'}>
                                    {this.renderStatus()}
                                </div>
                            </div>
                        </div>

                        {this.renderInstructions()}

                        <H2>How to participate in the challenge</H2>
                        <ol>
                            <li>Sign in with your ADI credentials.</li>
                            <li>Connect your Strava account. Make sure that your activities either public or shared with followers.</li>
                            <li>Begin or finish your rides at the office location.</li>
                        </ol>
                        <H2>Other activities</H2>
                        <ul>
                            <li>Join our <a href={'https://www.strava.com/clubs/43621'}>Strava biking club</a></li>
                            <li><Link to={'/'}>Return To Start Page</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export const OfficeCyclingComponent = withRouter<IOfficeProps, any>(Cycling);
