import { HttpApi } from './http-api';
import { cache } from '../utils/cache';
import { IActivity, IOffice } from '../types/office';
import {ILeader} from '../types/leader';

export class OfficeService {
    @cache()
    static async listOffices(company: string): Promise<IOffice[]> {
        try {
            const { items } = await HttpApi.get('offices');
            if (!items) {
                return [];
            }
            return (items);
        } catch (e) {
            console.log(e);
        }
        return [];
    }

    @cache()
    static async getOffice(officeId: string): Promise<IOffice|undefined> {
        try {
            const office = await HttpApi.get(`offices/${officeId}`);
            if (!office) {
                return undefined;
            }
            return office;
        } catch (e) {
            console.log(e);
        }
        return undefined;
    }

    @cache()
    static async getLeaders(officeId: string): Promise<ILeader[]> {
        try {
            const { items } = await HttpApi.get(`offices/${officeId}/leaders`);
            if (!items) {
                return [];
            }
            return items
                .sort((a: ILeader, b: ILeader) => {
                    if (a.points === b.points) {
                        return a.totalDistance < b.totalDistance;
                    }
                    return a.points < b.points;
                });
        } catch (e) {
            console.log(e);
        }
        return [];
    }


    static async getActivities(officeId: string): Promise<IActivity[]> {
        try {
            const { items } = await HttpApi.get(`offices/${officeId}/activities`);
            if (!items) {
                return [];
            }
            return items.sort((a: IActivity, b: IActivity) => {
                if (a.startDate === b.startDate) {
                    return a.distance < b.distance;
                }
                return a.startDate < b.startDate;
            });
        } catch (e) {
            console.log(e);
        }
        return [];
    }
}
