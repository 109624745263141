import { storage } from './storage';
import { UserModel } from '../models/user-state';
import { ConfigService } from './config';

const _rh = async (r: Response) => {
    if (!r.ok) {
        console.log('Response is not okay', r);
    }
    if (r.status === 401) {
        UserModel.reset();
        await UserModel.init();
    }
    const json = await r.json();
    if (json.error) {
        throw new Error(json.error);
    } else {
        if (!r.ok) {
            throw new Error(json.message || json.errorMessage || r.statusText);
        }
    }
    return json;
};

const mode = 'cors';
const credentials: RequestCredentials = 'omit';

export const HttpApi = {
    async url(svc: string): Promise<string> {
        if (svc.startsWith('http://') || svc.startsWith('https://')) {
            return svc;
        }
        await ConfigService.init();
        let prefix = ConfigService.config.apiBaseUrl || '/external/';
        if (ConfigService.config.apiBaseUrl &&
            ConfigService.config.apiBaseUrl.lastIndexOf('/') !==
            ConfigService.config.apiBaseUrl.length - 1
        ) {
            prefix += '/';
        }
        svc = svc ? svc : '';
        if (svc.indexOf('/') === 0) {
            svc = svc.substr(1, svc.length - 1);
        }
        return prefix + svc;
    },

    headers(): Headers {
        const headers: any = { 'Content-Type': 'application/json' };
        const token = storage.get('token');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        return headers;
    },

    init(method = 'GET', body: string | undefined = undefined): RequestInit {
        return {
            mode,
            method,
            credentials,
            body,
            headers: HttpApi.headers()
        };
    },

    async get(path: string): Promise<any> {
        await UserModel.assureCognitoSession();
        console.log(path);
        return fetch(
            await HttpApi.url(path),
            HttpApi.init()
        )
            .then(_rh)
            .catch((e) => {
                console.log(e);
                throw e;
            });
    },

    async post(path: string, params: any): Promise<any> {
        await UserModel.assureCognitoSession();
        return fetch(
            await HttpApi.url(path),
            HttpApi.init('POST', JSON.stringify(params))
        ).then(_rh);
    },

    async put(path: string, params: any): Promise<any> {
        await UserModel.assureCognitoSession();
        return fetch(
            await HttpApi.url(path),
            HttpApi.init('PUT', JSON.stringify(params))
        ).then(_rh);
    },

    async delete(path: string): Promise<any> {
        await UserModel.assureCognitoSession();
        return fetch(
            await HttpApi.url(path),
            HttpApi.init('DELETE')
        ).then(_rh);
    }
};
