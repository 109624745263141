import { action, computed, observable } from 'mobx';
import { IBreadcrumb } from '../types';

const {PUBLIC_URL } = process.env;

export class NavigationModel {
    @observable
    breadcrumbs: IBreadcrumb[];

    @observable
    online: boolean = true;

    constructor() {
        this.breadcrumbs = [];
        window.addEventListener('online', () => {
            this.refresh();
        });
        window.addEventListener('offline', () => {
            this.refresh();
        });
        this.online = window.navigator.onLine;
    }

    @action
    refresh() {
        this.online = window.navigator.onLine;
    }

    @computed
    get title() {
        if (this.breadcrumbs.length === 0) {
            return ' ';
        }
        return this.breadcrumbs[this.breadcrumbs.length - 1].text + '';
    }

    @computed
    get items() {
        return this.breadcrumbs;
    }

    @action
    clear() {
        this.breadcrumbs = [];
    }

    @action
    add(href: string, text: string) {
        return this.push({
            href,
            text
        });
    }

    @action
    push(breadcrumb: IBreadcrumb) {
        return this.breadcrumbs.push(breadcrumb);
    }

    @action
    go(path: string, absolute = false) {
        if (absolute) {
            window.location.replace(path);
            return;
        }
        if (!path.startsWith(PUBLIC_URL, 0)) {
            window.location.pathname = `${PUBLIC_URL}${path}`;
        } else {
            window.location.pathname = `${path}`;
        }
    }
}

export const AppNavigator = new NavigationModel();
