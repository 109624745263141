import Amplify from '@aws-amplify/core';
import { IAppConfig } from '../types';
import { UserModel } from '../models/user-state';

export const ConfigService = {
    config: {} as IAppConfig,
    initialized: false,
    isFetching: false,
    ticks: 0,
    init: async () => {
        if (!ConfigService.initialized && !ConfigService.isFetching) {
            ConfigService.isFetching = true;

            const {
                REACT_APP_API_ROOT,
                REACT_APP_API_AWS_USER_POOL_ID: userPoolId,
                REACT_APP_API_AWS_USER_POOL_WEB_CLIENT_ID: userPoolWebClientId,
                REACT_APP_API_AWS_REGION: region,
                REACT_APP_API_AWS_COGNITO_DOMAIN,
            } = process.env;

            const redirectUrl = window.location.origin + '/';

            ConfigService.config = {
                apiBaseUrl: REACT_APP_API_ROOT
            };
            try {
                Amplify.configure({
                    userPoolId,
                    userPoolWebClientId,
                    region,
                    oauth: {
                        domain: REACT_APP_API_AWS_COGNITO_DOMAIN,
                        scope: ['email', 'openid', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
                        redirectSignIn: redirectUrl,
                        redirectSignOut: redirectUrl,
                        responseType: 'token'
                    }
                });

                await UserModel.init();
            } catch (e) {
                console.log(e);
            }

            ConfigService.isFetching = false;
            ConfigService.initialized = true;
        }
        return new Promise((resolve, reject) => {
            const int = setInterval(() => {
                if (ConfigService.initialized) {
                    clearInterval(int);
                    resolve();
                } else {
                    ConfigService.ticks++;
                    if (ConfigService.ticks > 1000) {
                        clearInterval(int);
                        reject();
                    }
                }
            }, 40);
        });
    }
};

ConfigService.init().then().catch((e) => console.log(e));
