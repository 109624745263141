import React, { Component } from 'react';
import './welcome.scss';
import { Redirect, Link } from 'react-router-dom';
import { UserModel } from '../../../models/user-state';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AppNavigator } from '../../../models/navigation';
import classNames from 'classnames';
import logo from './ADI-Logo-AWP-Tagline-KO-White.svg';
import { om } from '../../../models/offices';
import {SKELETON} from "@blueprintjs/core/lib/esnext/common/classes";
import {FormGroup, InputGroup} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

const { PUBLIC_URL } = process.env;

@observer
class Welcome extends Component {

    @observable
    isRedirect = false;

    checkAuthState() {
        if (UserModel.isUserAuthenticated || !AppNavigator.online) {
            // this.isRedirect = true;
        }
    }

    componentDidMount(): void {
        AppNavigator.clear();
        AppNavigator.add('/', 'Bike to Work Month');

        this.checkAuthState();
        om.load().then();
        setTimeout(() => {
            this.checkAuthState();
        }, 1000);


    }

    public render() {
        if (this.isRedirect) {
            return <Redirect to={'/'}/>;
        }
        return this.renderPublic();
    }

    public renderPublic() {

        const loadingClass = om.isOfficeListLoading ? SKELETON : '';

        return <div className={classNames('welcomeComponent', 'public')}>

            <div className="welcomeLeftBlock">
                <img src={logo} width={120} className={'appLogo'} alt={'Logo'}/>
            </div>
            <div className="welcomeBlock">
                <div className="textBlock">
                    <h1 >
                        Since now
                        <br />You are in control of your body!
                    </h1>
                    <p>Join our project to stay healthy. Find your home office
                        and discover your new ground.</p>
                    <h2>Participating Locations</h2>
                    <div><form>
                        <FormGroup
                            label="Select location to get started"
                            labelInfo="  "
                            inline={true}
                        >
                        <InputGroup
                            fill={true}
                            leftIcon={IconNames.SEARCH}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                om.filterText = e.currentTarget.value;
                            }}
                            defaultValue={om.filterText}
                            placeholder={'Search for Location'}>
                        </InputGroup>
                        </FormGroup>
                    </form></div>
                    <div className={'office-list'}>
                        {om.filteredOffices.map(office => (
                            <Link
                                key={office.officeId}
                                className={'location'}
                                to={`/adi/${office.officeId}/`}
                                style={{backgroundImage: office.picture ? `url(${PUBLIC_URL}${office.picture})` : ''}}>

                                <h3 className={loadingClass}>{office.name}</h3>
                                <p className={loadingClass}>
                                    {office.address.street}
                                </p>
                                <p className={loadingClass}>
                                    {office.address.city}, {office.address.state}, {office.address.country}
                                </p>
                            </Link>
                        ))}
                    </div>


                    <div className='footer'>
                        Built with <span role="img" aria-label="Love">♥️</span> at Analog Garage. <a
                            style={{color: '#ffffff'}}
                            href="mailto:philipp.tkachev@analog.com?Subject=Bike2Work&Body=Hello%2C%20%0AI%20would%20like%20to%20report%20the%20following%20problem%3A%0A%0A...%0AI%20tried%20these%20steps%20to%20solve%20it%20on%20my%20own%3A%0A1.%20...%0A2.%20...%0A%0AI%20need%20to%20solved%20this%20problem%20because%20...%0A%0A%0A"
                        >Help!</a>
                    </div>
                </div>
            </div>
        </div>;
    }

    public renderPrivate() {
        return (<div className={classNames('welcomeComponent', 'private')}>
            <div className="mainBlock">


            <p>&nbsp;</p>
            </div>
        </div>);
    }
}


export default Welcome;
