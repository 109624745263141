export interface ILeader {
    userId: string;
    officeId: string;
    companyId: string;
    picture: string;
    firstName: string;
    lastName: string;
    stravaUsername: string;
    challengeMonth: string;
    points: number;
    totalRides: number;
    totalDistance: number;
    goal: number;
    accomplished: number;
    rankingPosition: number;
    rankingWeight?: number;
}

export const fakeLeader: ILeader = {
    accomplished: 0,
    companyId: '-',
    firstName: '...',
    goal: 0,
    lastName: '...',
    officeId: '-',
    challengeMonth: '2021-07',
    picture: '',
    points: 0,
    stravaUsername: '-',
    totalDistance: 0,
    totalRides: 0,
    rankingPosition: 0,
    userId: '-'
};

export interface ILeadersResponse {
    items: ILeader[];
}
