import React, { Component } from 'react';
import { Button, Callout, H1, Intent } from '@blueprintjs/core';
import './office.scss';
import { IconNames } from '@blueprintjs/icons';
import { AppNavigator } from '../../models/navigation';
import { RouteComponentProps, withRouter } from 'react-router';
import { om } from '../../models/offices';
import { observer } from 'mobx-react';
import { Classes } from '@blueprintjs/core/';
import { UserModel } from '../../models/user-state';
import LoginComponent from '../common/login/login';
import { HttpApi } from '../../service/http-api';
import { storage } from '../../service/storage';
import { ILeader } from '../../types/leader';
import ReactMarkdown from 'react-markdown';
import { IActivity } from '../../types/office';
import { convertMetersToKm, convertSecToHumanTime, getStravaPic, mapActivityTypeToImage } from '../../utils/formatters';

interface IOfficeProps extends RouteComponentProps<any> {
    companyId: string;
    officeId: string;
}

@observer
export class Office extends Component<IOfficeProps> {
    componentDidMount(): void {
        AppNavigator.clear();
        AppNavigator.add('/', 'Bike to Work');
        const {officeId} = this.props.match.params;
        if (officeId) {
            om.loadOffice(officeId).then(() => {
                if (om.activeOffice) {
                    AppNavigator.add(`/${ om.activeOffice.companyId }/${ om.activeOffice.officeId }/`,
                        om.activeOffice.name);
                }
            });
            om.loadOfficeActivities(officeId).then();
            om.loadStravaProfile().then().catch();
        }
    }

    public renderStravaConnectBanner() {
        return <Callout title={ 'Connect your Strava profile to start tracking your efforts' }
                        intent={ Intent.NONE } icon={ IconNames.CYCLE }>
            <p>First matched activity will place you on the leaderboard.</p>
            { this.renderStravaConnectButton('Connect Strava') }
        </Callout>;
    }

    public renderStravaConnectButton(text: string) {
        return (<Button onClick={ () => {
                const userId = storage.get('submitterId');
                const {officeId, companyId} = this.props.match.params;

                HttpApi.get(`strava/join?userId=${ userId }&officeId=${ officeId }&companyId=${ companyId }`)
                    .then((data) => {
                        AppNavigator.go(data.url, true);
                    });
            } } intent={ Intent.PRIMARY }>{ text }</Button>
        );
    }

    public renderStravaConnection() {
        if (!om.activeStravaProfile) {
            return this.renderStravaConnectBanner();
        }

        return <Callout intent={ Intent.SUCCESS } title={ 'Strava Connected' }>
            <div className={ 'stravaProfile' }>
                <div className="picture"><img
                    src={ om.activeStravaProfile.picture } width={ 50 } height={ 50 }
                    alt={ om.activeStravaProfile.firstName }/></div>
                <div className="fio">
                    <a href={ 'https://www.strava.com/athletes/' + om.activeStravaProfile.stravaUsername }
                       rel="noopener noreferrer"
                       target={ '_blank' }>
                        { om.activeStravaProfile.firstName } { om.activeStravaProfile.lastName }
                    </a>
                </div>
            </div>
            <div className="reconnect">
                { this.renderStravaConnectButton('Reconnect Strava') }
            </div>
        </Callout>;
    }

    public renderStatus() {
        if (UserModel.isUserAuthenticated) {
            return this.renderStravaConnection();
        } else {
            return <Callout title={ 'Join the Challenge' } intent={ Intent.NONE } icon={ IconNames.CYCLE }>
                <p>Sign in using single-sign-on flow. Then you will be prompted to connect your Strava account.</p>
                <LoginComponent redirectToReferrer={ true }/>
            </Callout>;
        }
    }

    public makeStravaProfileUrl(leader: ILeader) {
        const {stravaUsername} = leader;

        if (!stravaUsername) {
            return '#athlete-has-no-strava-username';
        }

        return 'https://www.strava.com/athletes/' + stravaUsername;
    }

    renderActivityCard(a: IActivity) {
        return <div className={ 'card' } key={a.activityId}>
            <div className="activity">

                <div className="athlete">
                    <img className="athletePhoto"
                     alt={a.firstName}
                     src={getStravaPic(a.userId)} />
                </div>
                <div className="athleteName">
                    <a href={ `https://strava.com/athletes/${a.stravaUserId}` } target="_blank" rel="noopener noreferrer">
                        {a.firstName} {a.lastName}
                    </a>
                </div>
                <div className="type">
                    <img src={ mapActivityTypeToImage(a.type) } alt={ a.type } title={a.type} width={24} height={24}/>
                </div>
                <div className="time" title={a.startDateLocal}>{convertSecToHumanTime(a.movingTime)}</div>
                <div className="distance">{convertMetersToKm(a.distance)}km</div>
            </div>
        </div>;
    }


    public renderLeaderboard() {
        return <div className={ 'leaderboard' }>
            <H1>Active Recently</H1>
            <div className={ 'cards' }>
                {om.activities.map((activity) => {
                    return this.renderActivityCard(activity);
                })
                }
            </div>
        </div>;
    }

    public renderInstructions() {
        if (!om.activeOffice) {
            return;
        }

        if (!om.activeOffice.instructions) {
            return;
        }

        return (
            <ReactMarkdown children={ om.activeOffice?.instructions }/>
        );
    }

    public render() {
        const extraClass = om.activeOffice ? '' : Classes.SKELETON;
        return (
            <div className={ 'officeComponent' }
                 style={ {backgroundImage: `url(${ om.activeOfficeBackgroundUrl })`} }>
                <div className={ 'overlay' }>
                    <div className={ 'container' }>
                        <div className="officeHeader">
                            <H1 className={ extraClass }>{ om.activeOffice?.name || '...' }</H1>
                        </div>
                        <div className="officeIntro">
                            <div className="left">
                                <div className="address">
                                    <p className={ extraClass }>{ om.activeOffice?.address.street || '.' }</p>
                                    <p className={ extraClass }>{ om.activeOffice?.address.city || '.' }, { om.activeOffice?.address.state || '.' } { om.activeOffice?.address.zip || '.' }</p>
                                    <p className={ extraClass }>{ om.activeOffice?.address.country || '.' }</p>
                                    <p className={ extraClass }>Latitude: { om.activeOffice?.lat || '.' }</p>
                                    <p className={ extraClass }>Longitude: { om.activeOffice?.lon || '.' }</p>
                                    <p className={ extraClass }
                                       title={ 'We detect starts and stops using this radius' }>Trigger
                                        radius: { om.activeOffice?.radius || '.' } m.</p>
                                </div>
                                { this.renderLeaderboard() }
                            </div>
                            <div className="right">
                                <img src={ process.env.PUBLIC_URL + om.activeOffice?.picture }
                                     height={ 250 }
                                     width={ 250 }
                                     alt="Office"
                                     className={ extraClass }/>
                                <div className={ 'signInOrConnect' }>
                                    { this.renderStatus() }
                                </div>
                            </div>
                        </div>

                        { this.renderInstructions() }


                    </div>
                </div>
            </div>
        );
    }
}

export const OfficeComponent = withRouter<IOfficeProps, any>(Office);
