import { ActivityType } from 'strava';
import { lazy } from 'react';

export function localDate(ts: Date | number | string): string {
    const d = new Date(ts);
    return d.toLocaleDateString();
}

export function localTime(ts: Date | number | string): string {
    const d = new Date(ts);
    return d.toLocaleTimeString();
}

export function ucFirst(s: string): string {
    return s[0].toUpperCase() + s.slice(1);
}

export function sanitizeString(s: string): string {
    return s.toLowerCase().replace(/[^a-z]/ig, ' ');
}

export function sanitizeDomain(s: string): string {
    return s.toLowerCase().replace(/[^a-z0-9-.]/ig, '');
}

/**
 * Interpolate string with variables.
 * Works similar to sprintf but missing all the formatting capabilities.
 * Use "%s" to indicate where variable should be inserted.
 *
 * @param {string} template
 * @param {string[]} rest
 */
export function sp(template: string, ...rest: string[]): string {
    return rest.reduce((acc, cv) => {
        return acc.replace('%s', cv);
    }, template);

}


export function getCurrentChallengeMonth(): string {
    const dt = new Date();
    const [date] = dt.toISOString().split('T');
    const [y, m] = date.split('-');
    return [y, m].join('-');
}


export function convertMetersToKm(m: number): string {
    return (m / 1000).toFixed();
}



export function convertSecToHumanTime(s: number): string {
    if (s < 60) {
        return s.toString() + 's';
    } else if (s < 3600) {
        return (s / 60).toFixed().toString() + 'm';
    } if (s < 3600*24) {
        return (s / 3600).toFixed().toString() + 'h';
    } else {
        return (s / 3600 / 24).toFixed().toString() + 'd';
    }
}



export function mapActivityTypeToImage(type: ActivityType): string {

    const activities = {
        alpineski: 'Ski',
        backcountryski: 'Ski',
        canoeing: 'Kayaking',
        crossfit: 'WeightTraining',
        ebikeride: 'EBikeRide',
        elliptical: 'Other',
        golf: 'Other',
        handcycle: 'Handcycle',
        hike: 'Hike',
        iceskate: 'IceSkate',
        inlineskate: 'InlineSkate',
        kayaking: 'Kayaking',
        kitesurf: 'Kitesurf',
        nordicski: 'Ski',
        ride: 'Bike',
        rockclimbing: 'RockClimbing',
        rollerski: 'Ski',
        rowing: 'Rowing',
        run: 'Run',
        sail: 'Water',
        skateboard: 'Other',
        snowboard: 'Snowboard',
        snowshoe: 'Snowshoe',
        soccer: 'Other',
        stairstepper: 'Run',
        standuppaddling: 'StandUpPaddling',
        surfing: 'Surfing',
        swim: 'Water',
        velomobile: 'Velomobile',
        virtualride: 'Bike',
        virtualrun: 'Run',
        walk: 'Walk',
        watersport: 'Water',
        weighttraining: 'WeightTraining',
        wheelchair: 'Wheelchair',
        windsurf: 'Windsurf',
        wintersport: 'Snow',
        workout: 'Other',
        yoga: 'Yoga'
    };
    let name = 'Other';
    const typeKey = type.toLowerCase();
    if (activities[typeKey]) {
        name = activities[typeKey];
    }
    return `/images/strava/${name}NormalSmall.svg`;
}

export function getStravaPic(userId: string): string {
    const {REACT_APP_API_ROOT} = process.env;
    return `${REACT_APP_API_ROOT}strava/picture/${userId}/`;
}

