import { ActivityType } from 'strava';

export interface IOffice {
    officeId: string;
    companyId: string;
    name: string;
    instructions: string;
    picture: string;
    backgroundImage?: string;
    address: {
        street: string;
        city: string;
        zip: string;
        country: string;
        state: string;
    }
    lat: number;
    lon: number;
    radius: number;
    rank: number;
    active: boolean;
}

export const officeFake: IOffice = {
    officeId: '-',
    companyId: '-',
    instructions: '-',
    picture: '/icon.png',
    name: 'ADI Boston',
    lat: 0,
    lon: 0,
    radius: 0,
    rank: 0,
    active: true,
    address: {
        street: '125 Summer St',
        city: 'Boston',
        zip: '02112',
        country: 'US',
        state: 'MA'
    }
};

export interface IOffices {
    items: IOffice[];
}
export interface IActivity {
    officeId: '-';
    companyId: '-';
    userId: '-';
    startDate: string;
    startDateLocal: string;
    type: ActivityType;
    distance: number;
    movingTime: number;
    activityId: number;

    picture: string;
    firstName: string;
    lastName: string;
    stravaUserId: string;

}
